<template>
  <div>
    <div class="background-circle left-circle" />
    <div class="background-circle right-circle" />
    <a href="https://www.teamgather.co">
      <g-image :src="gatherLogo" size="small" class="logo" alt="Gather logo" />
    </a>
    <page-header centered>
      <h1>{{ introPhrase }}</h1>
    </page-header>
    <loading v-show="loading" :loading="loading" />
    <container v-show="!loading">
      <grid centered>
        <grid-column eight centered>
          <g-form id="new-user-form" size="big" @submit="createAccount">
            <template #default="{ valid }">
              <form-validation
                :valid="valid"
                @update="(value) => (isFormValid = value)"
              />
              <template v-if="!existingFirstName">
                <h2>What’s your name?</h2>
                <form-field-group equal-width>
                  <form-field required>
                    <form-input
                      name="First Name"
                      :rules="['required', 'max:255']"
                      placeholder="First Name"
                    />
                  </form-field>
                  <form-field required>
                    <form-input
                      name="Last Name"
                      :rules="['max:255']"
                      placeholder="Last Name"
                    />
                  </form-field>
                </form-field-group>
              </template>
              <h2>What’s the name of your company?</h2>
              <form-field required>
                <form-input
                  name="Organization Name"
                  :rules="['required', 'max:255', 'min:2']"
                  placeholder="Company Name"
                />
              </form-field>
              <h2>
                In a few words, what are you hoping to achieve with Gather?
              </h2>
              <form-field required>
                <form-textarea
                  name="Organization Goal"
                  :rules="['required', 'max:500']"
                  placeholder="I want to..."
                />
              </form-field>
              <flex justify="space-around">
                <form-btn
                  variant="default"
                  type="reset"
                  size="huge"
                  form-id="new-user-form"
                  @click.native="logout"
                >
                  Cancel
                </form-btn>

                <form-btn
                  variant="primary"
                  type="submit"
                  size="huge"
                  :invalid="!isFormValid || !currentUser"
                  form-id="new-user-form"
                >
                  Continue
                </form-btn>
              </flex>
            </template>
          </g-form>
        </grid-column>
      </grid>
    </container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import gImage from '@/components/v2/image.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formInput from '@/components/v2/form/input.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formTextarea from '@/components/v2/form/textarea.vue'
import flex from '@/components/v2/flex.vue'

import gatherLogo from '@/assets/img/gather-logo-blue.png'

import { api } from '@/api'
import { toast } from '@/toasts'

export default {
  components: {
    gImage,
    pageHeader,
    grid,
    gridColumn,
    loading,
    container,
    gForm,
    formField,
    formFieldGroup,
    formInput,
    formBtn,
    formTextarea,
    formValidation,
    flex,
  },
  data() {
    return {
      gatherLogo,
      loading: false,
      isFormValid: true,
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.currentUser,
    }),
    existingFirstName() {
      return this.currentUser?.firstName
    },
    introPhrase() {
      return this.existingFirstName
        ? `👋  Hey ${this.existingFirstName}, nice to meet you!`
        : '👋  Hello!'
    },
  },
  watch: {
    currentUser: {
      handler(user) {
        // if the current user is already associated with an organization, move on to next page
        if (user?.organizationId) {
          this.$router.push({ name: 'tutorial' })
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$emit('changeNav', { type: 'noNavBar' })
  },
  methods: {
    async createAccount(submission) {
      try {
        this.loading = true

        await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/accounts`, {
          userId: this.currentUser.id,
          name: submission.get('Organization Name'),
          goal: submission.get('Organization Goal'),
          ...(submission.get('First Name')
            ? { userFirstName: submission.get('First Name') }
            : {}),
          ...(submission.get('Last Name')
            ? { userLastName: submission.get('Last Name') }
            : {}),
        })

        this.$store.commit('SET_CURRENT_USER', null)

        this.$router.push({ name: 'tutorial' })
      } catch (error) {
        toast.error(error)
        this.loading = false
      }
    },
    logout() {
      this.$auth.logout({ returnTo: `${window.location.origin}/sign_in` })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.logo {
  display: inline !important;
  max-width: 10em;
  margin: 1em 0 0 1em;
}

.background-circle {
  position: fixed;
  z-index: -1;
  width: 80vh;
  height: 80vh;
  border-radius: 100%;
  opacity: 0.5;
  transition: width 1s, height 1s;
}

.left-circle {
  top: -250px;
  right: auto;
  bottom: auto;
  left: -400px;
  background-color: @light-green;
}

.right-circle {
  top: auto;
  right: -400px;
  bottom: -200px;
  left: auto;
  background-color: @light-blue;
}

@media screen and (max-width: 1000px) {
  .background-circle {
    width: 70vh;
    height: 70vh;
  }
}

@media screen and (max-width: 768px) {
  .background-circle {
    width: 0;
    height: 0;
    transition: width 2s, height 2s;
  }
}
</style>
