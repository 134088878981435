<template>
  <validation-provider v-slot="{ errors }" :rules="rules.join('|')">
    <textarea
      v-model.trim="value"
      :rows="rows"
      :name="name"
      type="text"
      :placeholder="placeholder"
      :class="{ 'input-error': !!errors[0] }"
    />
    <span class="error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from '@/extensions/v2/validation'

export default {
  components: { ValidationProvider },
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    initialValue: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      value: this.initialValue,
    }
  },
  watch: {
    initialValue: {
      handler(newValue) {
        this.value = newValue
      },
    },
    value: {
      handler(newValue) {
        this.$emit('update', newValue)
      },
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.error {
  color: @error-red;
}

.input-error {
  border: @error-border !important;
}
</style>
